import axios, { AxiosResponse } from "axios";
import Session from "supertokens-auth-react/recipe/session";
import { Customer, FamilyMember } from "../types";

export async function getJWT() {
  if (await Session.doesSessionExist()) {
    let jwt = await Session.getAccessToken();
    return jwt;
  }
}

export const customerAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_JAVA,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Authorization: "Bearer ",
  },
});

export const fetchNewCustomer = async (
  data: Customer
): Promise<AxiosResponse<Customer>> => {
  const token = await getJWT();
  return customerAxiosInstance.post(
    "/customersss",
    {
      ...data,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const fetchDeleteCustomerById = async (
  id: number | string
): Promise<AxiosResponse<Customer>> => {
  const token = await getJWT();
  return customerAxiosInstance.delete(`/customers/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const fetchCreateFamilyMember = async (
  data: FamilyMember
): Promise<AxiosResponse<FamilyMember>> => {
  const token = await getJWT();
  return customerAxiosInstance.post(
    `/family/`,
    { ...data },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const fetchUpdateFamilyMember = async (
  data: FamilyMember
): Promise<AxiosResponse<FamilyMember>> => {
  const token = await getJWT();
  const { id, ...finalData } = data;
  return customerAxiosInstance.post(
    `/family/${id}`,
    { ...finalData },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const fetchDeleteFamilyMember = async (
  id: number
): Promise<AxiosResponse<FamilyMember>> => {
  const token = await getJWT();
  return customerAxiosInstance.delete(`/family/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const fetchAddNewInsurance = async (
  data: any
): Promise<AxiosResponse<any>> => {
  const token = await getJWT();
  return customerAxiosInstance.post(
    `/insurance/`,
    data, // Pass the data directly as the request body
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
        type: "formData",
      },
    }
  );
};

export const fetchAllInsuranceForCustomer = async (
  id: string
): Promise<AxiosResponse<any>> => {
  const token = await getJWT();
  return customerAxiosInstance.get(`/insurance/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const fetchUpdateCustomerById = async (data: {
  data: Customer;
  id: string;
}): Promise<AxiosResponse<Customer>> => {
  const token = await getJWT();
  return customerAxiosInstance.post(
    `/customerss/${data.id}`,
    {
      ...data.data,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};
